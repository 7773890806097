<template>
  <b-row class="my-2">
    <b-col>
      <div class="text-center">
        Current Page :  <kbd>{{ store.current_page }}</kbd>
      </div>
    </b-col> 
    <b-col
      lg="6"
      class="my-1 mx-auto"
    >
      <b-form-group
        label="Filter"
        label-cols-sm="2"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input 
            id="filterInput"
            v-model="filterItems"
            size="sm"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="$emit('filter-now')"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col class="text-center">
      <slot name="header-link" />
      <b-button
        size="sm"
        class="mr-1 btn-success"
        @click="$emit('add-new')"
      >
        <i class="fa fa-plus" /> Add New
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'FilterComponent',
  props: {
    store : {
      type: Object,
      required: true
    },
    filter: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      count: 0
    }
  },
  computed: {
    filterItems: {
      get () { return this.filter; },
      set(word) { 
        this.$emit('change-page', 1);
        this.$emit('filter-word', word);
      }
    }
  },
}
</script>
