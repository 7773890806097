<template>
  <div>
    <b-table
      id="my-table"
      ref="itemTable"
      small
      responsive
      :busy.sync="isBusy"
      :items="fetch"
      :fields="store.fields"
      :current-page="store.current_page"
      :per-page="store.per_page"
      :filter="filter"
    >
      <template v-slot:cell(id)="row">
        {{ (store.current_page - 1) * store.per_page + (row.index + 1) }}
      </template>

      <template v-slot:cell(actions)="row">
        <div
          class="btn-group"
          role="group"
        >
          <b-button
            size="sm"
            class="mr-1 btn-info"
            @click="$emit('editNow', row.item, $event.target)"
          >
            <i class="fa fa-pencil" />
          </b-button>

          
          <b-button
            v-if="showpwdfield"
            size="sm"
            class="mr-1 btn-primary"
            @click="$emit('changePassword', row.item, $event.target)"
          >
            <i class="fa fa-key" />
          </b-button>

            
          <b-button
            size="sm"
            class="mr-1 btn-warning"
            @click="$emit('showNow', row.item, row.index, $event.target)"
          >
            <i class="fa fa-eye" />
          </b-button>

          <b-button
            size="sm"
            class="mr-1 btn-danger"
            @click="$emit('delNow', row.item.id)"
          >
            <i class="fa fa-close" />
          </b-button>
        </div>
      </template>
    </b-table>
      
    <b-row v-if="store.total > 5">
      <b-col
        sm="7"
        md="4"
        class="my-1 mx-auto"
      >
        <b-pagination
          :value="store.current_page"
          :total-rows="store.total"
          :per-page="store.per_page"
          align="fill"
          size="sm"
          class="my-0"
          @change="changePage($event)"
        />
      </b-col>
    </b-row>
  </div>    
</template>

<script>
export default {
  name: 'CrudComponent',
  props: {
    store : {
      type: Object,
      required: true
    },
    filter: {
      type: String,
      default: ''
    },
    fetch: {
      type: Function,
      required: true
    },
    formerr: {
      type: Boolean,
    },
    showpwdfield: {
      type: Boolean
    }
  },
  data() {
    return {
      isBusy: false,
    }
  },
  watch: {
    formerr(newVal){
      if(!newVal){
        this.$refs.itemTable.refresh();
      }
    }
  },
  methods: {
    changePage($event){
      this.$emit('changeNow', $event)
    }
  }
}
</script>
