<template>
  <b-container>    
    <b-overlay
      :show="isBusy"
      rounded="sm"
      spinner-type="grow"
      spinner-variant="primary"
    >      
      <FilterComponent
        :store="store" 
        :filter="filter"
        @filter-now="filter = '';changePage(1);" 
        @change-page="changePage($event)"
        @add-new="add()"
        @filter-word="filter = $event"
      />
      <b-alert
        show
        variant="danger"
        dismissible
      >
        <strong>Note:</strong> Also available at <a href="https://ssiplengg.com">https://ssiplengg.com</a>.
      </b-alert>
      
      <CrudComponent
        :store.sync="store"
        :filter="filter"
        :fetch="fetchItems"
        :formerr.sync="formError"
        :showpwdfield.sync="showChangePwd"
        @editNow="edit"
        @showNow="show"
        @delNow="del"
        @changeNow="changePage"
      />
    </b-overlay>

    <!-- Edit modal -->
    <b-modal
      id="modal"
      v-model="formError"
      centered
      :title="form.formTitle"
      hide-footer
    >
      <b-alert 
        v-if="alert.message" 
        :show="5" 
        dismissible 
        :variant="alert.type" 
        @dismissed="clearAlert"
      >
        {{ alert.message }}
      </b-alert>

      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group
          label="Name" 
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="name"
        >
          <b-form-input 
            id="name"
            v-model="$v.form.item.name.$model"
            size="sm"
            name="name"
            :state="validateState('name')"
            aria-describedby="name-live-feedback"
          />

          <b-form-invalid-feedback
            id="name-live-feedback"
          >
            This is a required field and must be at least 3 characters.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Email" 
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="email"
        >
          <b-form-input 
            id="email"
            v-model="$v.form.item.email.$model"
            size="sm"
            name="email"
            :state="validateState('email')"
            aria-describedby="email-live-feedback"
          />

          <b-form-invalid-feedback id="email-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>
            
        <b-form-group
          label="Employee No." 
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="emp_num"
        >
          <b-form-input 
            id="emp_num"
            v-model="$v.form.item.emp_num.$model"
            size="sm"
            name="emp_num"
            :state="validateState('emp_num')"
            aria-describedby="emp_num-live-feedback"
          />

          <b-form-invalid-feedback id="emp_num-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>
              
        <b-form-group
          label="City" 
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="city_id"
        >
          <b-form-select 
            v-model="$v.form.item.city_id.$model"
            size="sm"
            :state="validateState('city_id')"
            :options="store.cities"
          />

          <b-form-invalid-feedback id="city-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group  
          label="State:"
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="state"
        >
          <b-form-select
            v-model="$v.form.item.state_id.$model"
            size="sm" 
            :state="validateState('state_id')"
            :options="store.stateitems"
          />
        </b-form-group>
                  
        <b-form-group
          label="Role" 
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="roles"
        >
          <b-form-select
            v-model="$v.form.item.roles.$model"
            size="sm"
            multiple
            :state="validateState('roles')"
            :options="store.roles"
          />

          <b-form-invalid-feedback id="role-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="text-center">
          <b-button
            type="submit"
            variant="primary"
          >
            Submit
          </b-button>
          <!-- <b-button class="ml-2" @click="resetForm()">Reset</b-button> -->
        </div>
      </b-form>
    </b-modal>

    <!-- Password Modal -->
    <b-modal
      id="pwd-modal"
      v-model="pwdFormError"
      centered
      :title="form.formTitle"
      hide-footer
    >
      <b-alert
        v-if="alert.message"
        :show="5"
        dismissible
        :variant="alert.type"
        @dismissed="clearAlert"
      >
        {{ alert.message }}
      </b-alert>
      <b-form @submit.stop.prevent="onChangePassword">             
        <b-form-group
          label="Password" 
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="password"
        >
          <b-form-input 
            id="password"
            v-model="$v.form2.password.$model"
            size="sm"
            type="password"
            name="password"
            :state="validatePasswordState('password')"
            aria-describedby="password-live-feedback"
          />

          <b-form-invalid-feedback id="password-live-feedback">
            This is a required field.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group  
          label="Confirm Password" 
          label-cols-sm="4"
          label-cols-lg="3"  
          label-for="password_confirmation"
        >
          <b-form-input 
            id="password_confirmation"
            v-model="$v.form2.password_confirmation.$model"
            size="sm"
            type="password"
            name="password_confirmation"
            :state="validatePasswordState('password_confirmation')"
            aria-describedby="password_confirmation-live-feedback"
          />

          <b-form-invalid-feedback id="password_confirmation-live-feedback">
            This must be same as password provided above.
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="text-center">
          <b-button
            type="submit"
            variant="primary"
          >
            Submit
          </b-button>
          <!-- <b-button class="ml-2" @click="resetForm()">Reset</b-button> -->
        </div>
      </b-form>
    </b-modal>

    <!-- Show modal -->
    <b-modal
      id="show-modal"
      centered
      title="Show Item"
      ok-only
      size="md"
    >
      <ul class="list-group">
        <li
          v-for="(val, index) in form.item"
          :key="index"
          class="list-group-item"
        >
          {{ index + ' : ' + JSON.stringify(val, 0, 2) }}
        </li>
      </ul>
    </b-modal>
  </b-container>
</template>

<script>
import helper from "@/mixins/helper";
import { mapState, mapActions, mapGetters } from 'vuex';
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs, email } from "vuelidate/lib/validators";
import FilterComponent from '@/components/FilterComponent.vue';
import CrudComponent from '@/components/CrudComponent.vue';

export default {
  name: 'Users',
  components:{
    FilterComponent,
    CrudComponent
  },
  mixins: [validationMixin, helper],
  data() {
    return {
      showChangePwd: true,
      form: {
        formTitle: 'Create Item',
        mode: 'add',
        item: {
          id: null,
          name: null,
          email: null,
          emp_num: null,
          state_id: null,
          city_id: null,
          roles: [],
        }
      },
      form2: {
        password: null,
        password_confirmation: null,
      },
      isBusy: false,
      filter: ''
    }
  },
  validations: {
    form: {
      item: {
        name: {
          required,
          minLength: minLength(3)
        },
        email: {
          required,
          email
        },
        emp_num: {
          required
        },
        state_id: {
          required
        },
        city_id: {
          required
        },
        roles: {
          required
        },
      }
    },
    form2: {
      password: {
        required
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
  computed: {
    ...mapState({ alert: state => state.alert }),
    ...mapGetters("user", ['store']),
    formError: {
      get () { return this.store.formError; },
      set(){ }
    },
    pwdFormError: {
      get () { return this.store.pwdFormError; },
      set() { }
    }
  },
  watch: {
    pwdFormError(newVal){
      if(!newVal){
        this.$bvModal.hide('modal');
      }
    },
  },
  mounted(){
    this.setStates();
    this.setCities();
    this.setRoles();
  },
  methods: {
    ...mapActions("user", [
      "fetchItems", 
      "addItem", 
      "deleteItem", 
      "updateItem", 
      "changePage",
      "changePassword", 
      "setStates", 
      "setCities",
      "setFilter",
      "setRoles"
    ]),
    // 1. Prepare modal for creation of item.
    add() {
      this.$v.$reset();
      this.form.item = { 
        id: null, 
        name: null, 
        email: null,
        emp_num: null,
        state_id: null, 
        city_id: null,
        roles: []
      };
      this.form.formTitle = 'Create Item:';
      this.form.mode = 'add';
      this.$root.$emit('bv::show::modal', 'modal')
    },
    // Prepare model for updation.
    edit(item, button) {
      this.form = {
        formTitle: 'Edit Item:',
        mode: 'update',
        item: JSON.parse(JSON.stringify(item))
      };
      this.form.item.roles = item.roles.map(item => item.value);
      this.$root.$emit('bv::show::modal', 'modal', button)
    },
    // Prepare model for updation.
    changePwd(item, button) {
      this.form2.item = {
        password: null,
        password_confirmation: null
      };
      this.form = {
        formTitle: 'Change password:',
        mode: 'changePwd',
        item: JSON.parse(JSON.stringify(item))
      };
      this.$root.$emit('bv::show::modal', 'pwd-modal', button)
    },
    // 2. Validation after creating form.
    validateState(name) {
      const { $dirty, $error } = this.$v.form.item[name];
      return $dirty ? !$error : null;
    },

    validatePasswordState(name) {
      const { $dirty, $error } = this.$v.form2[name];
      return $dirty ? !$error : null;
    },
    // 3. Handle Submit - Call Store fundtion.
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }else{
        this.clean(this.form.item);
        if(this.form.mode == 'update'){
          this.updateItem(this.form.item);
        }else{
          this.addItem(this.form.item);
        }
      }        
    },

    onChangePassword() {
      this.$v.form2.$touch();
      if (this.$v.form2.$anyError) {
        return;
      }else{
        this.changePassword({
          id: this.form.item.id,
          password: this.form2.password,
          password_confirmation: this.form2.password_confirmation
        });
      }
    },
    // 4. Show Item details in another Modal.
    show(item, index, button) {
      this.form.item = item;
      this.$root.$emit('bv::show::modal', 'show-modal', button)
    },
    // 5. Delete the item.
    del(id) {
      let res = window.confirm("Are you sure?");
      if(res) this.deleteItem(id);
    },
  }
}
</script>
